// eslint-disable-next-line no-undef
const originalFetch = globalThis.fetch;
// eslint-disable-next-line no-undef
globalThis.fetch = globalFetch;

let getBearerToken = () => { return null; };

async function globalFetch( input, init ) {
    init = typeof init === "object" ? init : {};

    // create a relative url to test if the same origin
    const url = new URL( input, location.origin );

    // check if the fetch url is from the same origin
    if ( url.origin === location.origin ) {
        // add the bearer token to login user

        const headers = new Headers( init.headers );

        if ( !headers.get( "authorization" ) ) {
            const token = await getBearerToken();

            if ( token ) {
                // console.log( "Bearer Token Added", token.substr( 0, 20 ) + "..." );
                headers.set( "authorization", "Bearer " + token );
            }
        }

        init.headers = headers;
    }

    return originalFetch( input, init );
}


/**
 * Setup the bearer token callback
 * 
 * @param {() => Promise<string>} callback 
 */
export function setupAuthTokenCallback( callback ) {
    getBearerToken = callback;
}