const Sentry = require("sentry");

try {
    if (window) {
        window.addEventListener("focus", () => {
            // console.log("Window got focus");
            Sentry.addBreadcrumb("Window got focus.");
        });

        window.addEventListener("blur", () => {
            // console.log("Window lost focus");
            Sentry.addBreadcrumb("Window lost focus.");
        });
    }
} catch (err) {
    // ignore the error
}