const Sentry = require("@sentry/browser");

// check if the context is loaded in worker
const isWorker =
    typeof WorkerGlobalScope !== "undefined" &&
    self instanceof WorkerGlobalScope;

// setup a new session replay
const replay = isWorker
    ? null
    : Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
      });

module.exports.sentryReplay = replay;
