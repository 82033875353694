const Dexie = require( "dexie" ).default;

/**
 * Reduces the array to a single value
 * 
 * @param {Dexie.Table} db 
 */
function reduceAddon( db ) {

    /**
     * Reduces the collection to a single value
     * 
     * @param {Function} reducer 
     */
    async function reduce( reducer ) {
        // the accumulator 
        let acc = undefined;
        let index = 0;

        await this.each( ( row, cursor ) => {
            acc = reducer( acc, row, index++, cursor );
        } );

        return acc;
    }

    db.Collection.prototype.reduce = reduce;

    /**
     * Reduces the collection to a single value
     * 
     * @param {Function} reducer 
     */
    async function reduceTable( reducer ) {

        let collection = this.toCollection();

        return await collection.reduce( reducer );

    }

    db.Table.prototype.reduce = reduceTable;

}

Dexie.addons.push( reduceAddon );