import { onAuthStateChanged } from "./index";

/**
 * Get the bearer token for the current logged in user... Resolves with null if not logged in
 *
 * @returns {Promise<string|null>}
 */
export function getBearerToken() {
    return new Promise(resolve => {
        const unsubscribe = onAuthStateChanged(user => {
            unsubscribe();

            if (user) {
                user.getIdToken().then(
                    idToken => {
                        resolve(idToken);
                    },
                    error => {
                        resolve(null);
                    }
                );
            } else {
                resolve(null);
            }
        });
    });
}
