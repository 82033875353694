const Sentry = require("@sentry/browser");
require("./../../env.js");
const findButtonText = require("sentry/buttonClick");
const { sentryReplay } = require("./replay");

// eslint-disable-next-line no-console
console.log(
    "%c" + process.env.VERSION + " %c" + process.env.SENTRY_KEY,
    "color:green;",
    "color:grey"
);
// check if the context is loaded in worker
const isWorker =
    typeof WorkerGlobalScope !== "undefined" &&
    self instanceof WorkerGlobalScope;

Sentry.init({
    dsn: process.env.SENTRY_KEY,
    release: `walls-web-app@${process.env.VERSION}`,
    environment: process.env.NODE_ENV,
    replaysOnErrorSampleRate: isWorker
        ? 0
        : process.env.NODE_ENV === "production"
        ? 0.15
        : 1,
    replaysSessionSampleRate: isWorker
        ? 0
        : process.env.NODE_ENV === "production"
        ? 0.005
        : 0.01,
    tracesSampleRate: process.env.NODE_ENV === "production" ? 0.005 : 0.005,
    tracePropagationTargets: [
        "app.localhost",
        "https://testapp.wallsmetalroofing.com",
        "https://staging.wallsmetalroofing.com",
        "https://app.wallsmetalroofing.com",
    ],
    integrations: [
        sentryReplay,
        Sentry.browserTracingIntegration({
            enableInp: true,
        }),
    ].filter(Boolean),
    beforeSend(event, hint) {
        const error = hint.originalException;

        // ignoring all timeout errors. They are not useful in the sentry dashboard
        if (
            error &&
            error.name &&
            (error.name === "Timeout Error" || error.name === "Time Out")
        ) {
            // loaded inline to avoid circular dependencies
            const { timeoutCounter } = require("metrics/counters.js");
            timeoutCounter(error.api || "unknown");

            try {
                Sentry.metrics.increment("timeout-errors", 1, {
                    tags: {
                        api: error.api || "unknown",
                    },
                });
            } catch (err) {
                // no-op
            }
            // set the level to low
            event.level = "info";
        }

        // don't create new issues from development
        if (
            location.hostname === "app.localhost" ||
            process.env.NODE_ENV === "development"
        ) {
            return null;
        }

        return event;
    },
    beforeBreadcrumb: (breadcrumb, hint) => {
        if (breadcrumb.category === "ui.click") {
            const text = findButtonText(breadcrumb, hint);

            if (text) {
                breadcrumb.message += " ~ " + text;
            }
        }
        return breadcrumb;
    },
});

// add the api tag to the event
Sentry.addEventProcessor((event, hint) => {
    if (hint.originalException && hint.originalException.api) {
        event.tags = event.tags || {};
        event.tags.api = hint.originalException.api;
    }

    return event;
});

Sentry.setContext("is-worker", { is: isWorker });

// setup the old Raven functions
try {
    window.Raven = {};

    window.Raven.captureException = (...args) => {
        Sentry.captureException(...args);
    };

    window.Raven.lastEventId = Sentry.lastEventId;
    window.Raven.showReportDialog = eventId => {
        return Sentry.showReportDialog({ eventId });
    };

    // eslint-disable-next-line no-console
    window.Raven.config = () => {
        console.trace("Raven.config");
    };
    // eslint-disable-next-line no-console
    window.Raven.setUserContext = () => {
        console.trace("Raven.setUserContext");
    };

    window.Raven.context = callback => {
        callback();
    };
    // eslint-disable-next-line no-empty
} catch (err) {}

module.exports = Sentry;
require("sentry/onBackground");
