let prefix = "";
const Sentry = require( "sentry" );
const Dexie = require( "dexie" ).default;

// require the dexie addons
require( "./addons" );

const debug = false;

/**
 * Index db options
 * 
 * @typedef options
 * 
 * @property {String} name the name of the database
 * @property {String[]} stores a list of stores names
 * @property {String[]} fields the fields to index
 */
const dbOptions = [ {
    name: "test",
    stores: [ "main", "offline" ],
    fields: [
        "name,value,deleted,lastedit"
    ]
} ];

/**
 * Set a name prefix to use for the instance
 * 
 * @param {String} pre 
 */
function updatePrefix( pre ) {
    if ( typeof pre === "string" ) {
        prefix = pre;
    }
    return prefix;
}

/**
 * Initiate a dexie db object
 * 
 * @param {options} options 
 */
function initDexie( options ) {

    // eslint-disable-next-line no-console
    // console.log( `%cINIT Dexie '${options.name}'`, "color: gray;", options );
    Sentry.addBreadcrumb( {
        message: `INIT Dexie '${options.name}'`,
        data: options,
    } );
    // eslint-disable-next-line no-console
    if ( debug ) { console.trace( `INIT Dexie '${options.name}'` ); }

    // initiate a new dexie object
    const db = new Dexie( prefix + options.name );
    options.dexie = db;

    for ( let i = options.fields.length - 1; i >= 0; i-- ) {

        // get the field of columns for the db
        const field = options.fields[ i ];

        // create a stores object for each field
        let stores = {};
        for ( let store of options.stores ) {
            stores[ store ] = field;
        }

        // set the version of the table with decimal points
        const version = ( i * 0.1 ) + 1;
        let upgradeResult = db.version( version ).stores( stores );

        // upgrade the table
        if ( Array.isArray( options.upgrade ) && typeof options.upgrade[ i ] === "function" ) {
            upgradeResult.upgrade( options.upgrade[ i ] );
        }

        if ( debug ) {
            // eslint-disable-next-line no-console
            console.log( options.name, version, stores );
        }
    }

    return options;
}

/**
 * Get a dexie object by the database name
 * 
 * @param {String} name the table name
 * 
 * @example getDexie("test")
 * @returns {Dexie}
 */
function getDexie( name ) {

    for ( let option of dbOptions ) {

        if ( option.name === name ) {

            if ( !option.dexie ) {
                option.dexie = initDexie( option ).dexie;
            }
            return option.dexie;

        }
    }
    return null;

}

module.exports = getDexie;
module.exports.updatePrefix = updatePrefix;


getDexie.store = require( "./functions/store" );

/**
 * Return the list of registered tables
 * 
 * @returns {dbOptions}
 */
function getList() {
    return dbOptions;
}
getDexie.list = getList;

/**
 * Add a new table to the list of dexie index databases
 * 
 * @param {Object} options the options to start a db with tables
 * 
 * @example db.register({
 *     name: "test",
 *     stores: [ "main", "offline" ],
 *     fields: [
 *         "name,value,deleted,lastedit", // add a new array element for each new version of the table. 
 *         "name,value,example,deleted,lastedit" // Make sure that those fields contain all of the previous columns that need to be there
 *     ],
 *     upgrade: [// TODO: this needs to be implemented yet
 *          null,
 *          function// the function will only be called if its specified and needed to upgrade the version of database
 * ]
 * })
 */
function register( options ) {
    if ( debug ) {
        // eslint-disable-next-line no-console
        console.trace( "Register " + options.name );
    }
    return dbOptions.push( options );
}

getDexie.register = register;

getDexie.Dexie = Dexie;