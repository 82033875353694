// @ts-check
import { Dexie } from "indexdb/indexdb";

// setup index db using dexie
const db = new Dexie("wmr-metrics");
db.version(1).stores({
    metrics: "++id,type,time",
});

/**
 * Get the current list of events from local storage.
 *
 * @returns {Promise<(import("apis/metrics/features").CounterLogEntry & {id: number})[]>}
 */
export async function getMetricsList() {
    try {
        const list = await db.table("metrics").toArray();

        return list;
    } catch (err) {
        return [];
    }
}

/**
 * Remove the list of metrics from index db
 *
 * @param {number[]} ids
 */
export async function removeMetrics(ids) {
    try {
        if (ids.length === 0) {
            return;
        }

        await db.table("metrics").bulkDelete(ids);
    } catch (err) {
        // no-op
    }
}

/**
 *
 * @param {import("apis/metrics/features").CounterLogEntry["type"]} type
 * @param {import("apis/metrics/features").CounterLogEntry["data"]} data
 */
export async function logEntry(type, data) {
    /**
     * @type {import("apis/metrics/features").CounterLogEntry}
     */
    const entry = {
        type,
        time: Math.floor(Date.now() / 1000),
        data: /** @type {any} */ (data),
    };

    try {
        await db.table("metrics").add(entry);
    } catch (err) {
        console.error(err);
    }
}
