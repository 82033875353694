/**
 * Find the given click event button name if possible
 * 
 * @param {*} breadcrumb 
 * @param {*} hint 
 */
function findButtonText( breadcrumb, hint ) {
    const { target } = hint.event;

    // get the button text
    if ( typeof document.isElement === "function" && document.isElement( target ) ) {
        return findText( target );
    }

    return null;
}
module.exports = findButtonText;

const skipNames = [ "input", "body", "form" ];

function findText( element ) {

    // check if the element class has a button class added
    if ( /(btn)|(button)/i.test( element.classList.value ) ) {
        return element.innerText.trim();
    }

    // check that the parent node is a element and that the tag name is not in the skip list
    if ( document.isElement( element.parentNode ) && !skipNames.includes( element.parentNode.tagName.toLowerCase() ) ) {
        return findText( element.parentNode );
    }

    return null;
}