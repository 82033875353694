import "./../../../../env.js";
import { initializeApp } from "firebase/app";
import {
    getAuth as getFirebaseAuth,
    onAuthStateChanged as authChanged,
} from "firebase/auth";

initializeApp(process.env.FIREBASE);
const auth = getFirebaseAuth();

export function getAuth() {
    return getFirebaseAuth();
}

export function onAuthStateChanged(cb) {
    return authChanged(auth, cb);
}
