if (!global.process) {
    global.process = {};
}
if (!process.env) {
    process.env = {};
}

const firebaseDev = {
    apiKey: "AIzaSyBKpV05L108_az7ekqz89IKa6AkR7wrY_g",
    authDomain: "walls-web-app-dev.firebaseapp.com",
    databaseURL: "https://walls-web-app-dev.firebaseio.com",
    projectId: "walls-web-app-dev",
    storageBucket: "walls-web-app-dev.appspot.com",
    messagingSenderId: "1006178565691",
    appId: "1:1006178565691:web:ac93d6a54d47999c6b261e",
};
const firebaseProd = {
    apiKey: "AIzaSyCe-uHfRHkAcHJ7WhHI10RS4WNyqV_gIbc",
    authDomain: "delta-vial-166320.firebaseapp.com",
    databaseURL: "https://delta-vial-166320.firebaseio.com",
    projectId: "delta-vial-166320",
    storageBucket: "delta-vial-166320.appspot.com",
    messagingSenderId: "425607535563",
};
let env = process.env.NODE_ENV;
process.env.FIREBASE =
    env === "production" ||
    location.hostname.startsWith("testapp") ||
    location.hostname.startsWith("staging")
        ? firebaseProd
        : firebaseDev;
