// @ts-check
import { logEntry } from "./logEntry";

/**
 * Count the number of times a feature is used.
 *
 * @param {import("apis/metrics/features").TrackedFeatures} baseFeature
 * @param {Omit<import("apis/metrics/features").FeatureEntryData, "feature" | "page">} extra
 */
export function featureCounter(baseFeature, extra) {
    const location = extra.location || window.location.hash;

    /**
     * @type {Required<import("apis/metrics/features").FeatureEntryData>}
     */
    const data = {
        feature: baseFeature,
        page: process.env.page,
        ...extra,
        location,
    };

    logEntry("feature-count", data);
}

/**
 * Track the number of times a timeout occurs.
 *
 * @param {string} api
 */
export function timeoutCounter(api) {
    const data = {
        api: api,
        page: process.env.page,
    };
    logEntry("timeout", data);
}
