const db = require( "indexdb" );

/**
 * Gets the dexie storeObject
 * 
 * @name db.store
 * 
 * @param {string} dbName the database name to lookup
 * @param {string} store the store to use
 * 
 * @example db.store("test", "table").put()
 */
function storeValue( dbName, store ) {
    // get the database
    let dex = db( dbName );

    // get the table to put into
    let dxStore = dex[ store ];
    return dxStore;
};

module.exports = storeValue;